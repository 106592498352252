<template>
  <PageLayout>
    <template #header>
      <k-title>{{ location.name }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        color="primary"
        depressed
        tile
        @click="openUpdate"
      >
        {{ $t('actions.updateResource', { resource: $tc('location.title') }) }}
      </v-btn>
      <LocationForm
        v-model="updateDialog"
        :employer="employer"
        :request="updateRequest"
        :values="updateFormValues"
        :title="$tc('location.title')"
        is-update-form
        @change="getLocation"
      />
    </template>
    <template
      v-if="location"
      #default
    >
      <v-row>
        <v-col sm="6">
          <k-dossier class="pb-1">
            <k-display-group language-prefix="location.fields">
              <k-text-display field="costCenter">
                {{ location.costCenter || '-' }}
              </k-text-display>
              <k-text-display field="address">
                <AddressDisplay :address="location.address" />
              </k-text-display>
              <KLinkDisplay
                link-type="phone"
                field="contactDetail.phone"
                :href="location.contactDetail.phone"
              />
              <KLinkDisplay
                link-type="phone"
                field="contactDetail.mobilePhone"
                :href="location.contactDetail.mobilePhone"
              />
              <KLinkDisplay
                link-type="fax"
                field="contactDetail.fax"
                :href="location.contactDetail.fax"
              />
              <KLinkDisplay
                link-type="email"
                field="contactDetail.email"
                :href="location.contactDetail.email"
              />
              <KLinkDisplay
                link-type="website"
                field="contactDetail.website"
                :href="location.contactDetail.website"
              />
              <k-text-display field="iban">
                {{ location.iban }}
              </k-text-display>
            </k-display-group>
          </k-dossier>
        </v-col>
        <v-col sm="6">
          <InternalNote
            v-if="can('location.internalNote')"
            scope="location"
            :scope-id="location.id"
          />
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Location from '@/application/models/Location.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import AddressDisplay from '@/modules/address/components/AddressDisplay.vue';
import { show, update } from '@/modules/location/api/location.js';
import LocationForm from '@/modules/location/components/LocationForm.vue';
import KTitle from '@/components/layout/KTitle.vue';
import KDossier from '@/components/layout/KDossier.vue';
import InternalNote from '@/modules/internalNote/components/InternalNote.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Details',
  components: {
    InternalNote,
    KDossier,
    KTitle,
    KLinkDisplay,
    AddressDisplay,
    KDisplayGroup,
    LocationForm,
    PageLayout,
    KTextDisplay,
  },
  props: {
    location: {
      type: Object,
    },
    employer: {
      type: Object,
    },
    getLocation: {
      type: Function,
    },
  },
  data: () => ({
    updateFormValues: new Location(),
    updateDialog: false,
  }),
  computed: {
    ...mapGetters({ can: 'authorisation/can' }),
  },
  watch: {
    '$route.params.locationId': {
      immediate: true,
      handler() {
        if (this.can('location.internalNote')) {
          eventBus.$emit('internalNote', {
            scope: 'location',
            scopeId: this.location.id,
          });
        }
      },
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.location.index' },
        text: this.$tc('location.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.location.show' },
        text: this.location.name,
      },
    ]);
  },
  beforeDestroy() {
    eventBus.$emit('internalNote');
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    async openUpdate() {
      this.updateFormValues = new Location();
      const response = await show(this.location.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
